<template>
  <BaseDialog
    title="AI 熱門預約時段預測說明"
    width="576px"
    @close="$emit('close')"
  >
    <div class="content">
      使用 AI 模型根據 2023 年美業預約大數據進行預測與估算，預測 2024 每週與每個時段的預約狀況，提供熱門預約時段作為排班與營業的參考
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

export default defineComponent({
  name: 'ExplanationModal',
  components: { BaseDialog },
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>
.content {
    @apply mb-[32px] h-[324px] overflow-y-auto p-[12px];
    @apply border border-gray-40 rounded-[4px];
}
</style>
