var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{},[_c('PageTitle',{attrs:{"title":"AI 熱門預約時段預測","btnPlain":"","btn":"說明"},on:{"btnClick":function($event){_vm.modal.explanation = true}}}),_c('div',{staticClass:"mb-[30px]"},[_c('el-select',{attrs:{"value-key":"label"},on:{"change":_vm.onRangeChange},model:{value:(_vm.search.range),callback:function ($$v) {_vm.$set(_vm.search, "range", $$v)},expression:"search.range"}},_vm._l((_vm.dateRangeOptions),function(option){return _c('el-option',{key:option.label,attrs:{"value":option,"label":option.label}})}),1)],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"flex w-full bg-white",staticStyle:{"padding":"40px","border-radius":"4px"}},[_vm._m(0),_c('div',{staticClass:"flex w-full",staticStyle:{"gap":"2px","margin-right":"40px"}},_vm._l((_vm.displayData),function(dayData,index){return _c('div',{key:index,staticClass:"flex flex-col w-full items-center"},[_c('PeriodHeatmap',{staticClass:"w-full",attrs:{"data":_vm.dayPeriodData(dayData)}}),_c('p',[_vm._v(_vm._s(_vm.get(_vm.weeksLabel, index)))])],1)}),0),_c('div',{staticClass:"flex-shrink-0",staticStyle:{"padding-bottom":"32px"}},[_c('div',{staticClass:"h-full flex flex-col"},_vm._l((_vm.heatColor),function(stage,index){return _c('div',{key:stage.color,staticClass:"flex items-center h-full",staticStyle:{"gap":"10px"}},[_c('div',{staticClass:"h-full overflow-hidden",style:({
              backgroundColor: stage.color,
              width: '12px',
              borderTopLeftRadius: index === 0 ? '8px' : '0',
              borderTopRightRadius: index === 0 ? '8px' : '0',
              borderBottomLeftRadius: index === _vm.heatColor.length - 1 ? '8px' : '0',
              borderBottomRightRadius: index === _vm.heatColor.length - 1 ? '8px' : '0',
            })}),_c('p',{staticClass:"text-sub flex-shrink-0"},[_vm._v(_vm._s(stage.text))])])}),0)])]),(_vm.modal.explanation)?_c('ExplanationModal',{on:{"close":function($event){_vm.modal.explanation = false}}}):_vm._e(),(_vm.modal.noIndustry)?_c('BaseDialog',{attrs:{"title":"提示","hideCancel":""},on:{"close":function($event){_vm.modal.noIndustry = false},"confirm":function($event){_vm.modal.noIndustry = false}}},[_c('div',{staticStyle:{"margin-bottom":"42px"}},[_vm._v(" 請先至分店設定編輯分店所屬產業類別，即可顯示所屬產業預測資料 ")])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col justify-evenly mr-[10px]",staticStyle:{"padding-bottom":"32px"}},[_c('p',[_vm._v("凌晨")]),_c('p'),_c('p',[_vm._v("上午")]),_c('p'),_c('p',[_vm._v("下午")]),_c('p'),_c('p',[_vm._v("晚上")])])
}]

export { render, staticRenderFns }