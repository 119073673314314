<template>
  <div class="period-heatmap">
    <div class="periods-container">
      <div
        v-for="(heat, index) in data"
        :key="index"
        :style="{ height: `${periodHeight}px`, backgroundColor: heatColor(heat) }"
        class="period"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
export default defineComponent({
  name: 'PeriodHeatmap',
  props: {
    data: { type: Array, default: () => [] },
    periodHeight: { type: Number, default: 25 },
    label: { type: String, default: '' },
  },
  setup (props) {
    const heatColor = computed(() => {
      // return (heat) => {
      //   if (heat > 8) return '#4175f5'
      //   if (heat > 6) return '#6791f7'
      //   if (heat > 4) return '#9fb9f9'
      //   if (heat > 1) return '#c6d6fc'
      //   return '#ecf2fe'
      // }

      const colors = ['#ecf2fe', '#c6d6fc', '#9fb9f9', '#6791f7', '#4175f5']
      return (heat) => colors[heat - 1]

      // return () => getGradientColor(randomNum(0, 1), '#ecf2fe', '#4175f5')
    })

    function randomNum (min, max) {
      return Math.random() * (max - min) + min
    }

    // 将十六进制颜色转换为 RGB 格式
    function hexToRgb (hex) {
    // 去掉 "#" 号
      hex = hex.replace('#', '')
      // 将每两个字符一组转换成十进制数
      const bigint = parseInt(hex, 16)
      const r = (bigint >> 16) & 255
      const g = (bigint >> 8) & 255
      const b = bigint & 255

      return [r, g, b]
    }

    // 将 RGB 格式转换为十六进制颜色
    function rgbToHex (r, g, b) {
      return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
    }

    // 计算渐变颜色
    function getGradientColor (fraction, color1, color2) {
      const rgb1 = hexToRgb(color1)
      const rgb2 = hexToRgb(color2)

      const r = Math.round(rgb1[0] + fraction * (rgb2[0] - rgb1[0]))
      const g = Math.round(rgb1[1] + fraction * (rgb2[1] - rgb1[1]))
      const b = Math.round(rgb1[2] + fraction * (rgb2[2] - rgb1[2]))

      return rgbToHex(r, g, b)
    }

    return { heatColor }
  },
})
</script>

<style lang="postcss" scoped>
.periods-container {
  @apply rounded-[8px] overflow-hidden mb-[8px];
}

</style>
